<template>
    <div class="layout-content">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .layout-content{
        flex:1;
        background: #f2f3f5;
        position: relative; //用于给内容区设置高度
    }
</style>