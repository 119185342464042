<template>
    <div class="layout">
        <!-- headerView -->
        <div class="layout-top">
            <header-view></header-view>
        </div>
        
        <div class="layout-wrap">
            <!-- 侧边栏navbar -->
            <NavBar></NavBar>

            <!-- contentView -->
            <content-view></content-view>
        </div>

    </div>
</template>

<script>
    import NavBar from './NavBar.vue';
    import HeaderView from './HeaderView.vue';
    import ContentView from './ContentView.vue';
    export default {
        data(){
            return {

            }
        },
        components:{
            NavBar,HeaderView,ContentView
        }
        
    }
</script>

<style lang="scss" scoped>
    .layout{
        width: 100%;
        height: 100%;
        position: relative;
        .layout-top{
            position: absolute;
            top:0;
            right:0;
            left:0;
        }
        .layout-wrap{
            position: absolute;
            top:65px;
            right:0;
            left:0;
            bottom:0;
            display: flex;
            
        }

        
    }
</style>