<template>
  <div class="layout-navbar" :class="isCollapse ? 'layout-collapse' : ''">
    <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse" :collapse-transition="false" :router="true">
      <template v-for="(item, idx) in menuData">
        <template v-if="item.status">
          <template v-if="item.childMenu">
            <!-- 有二级子导航  -->
            <el-submenu :index="'n_' + idx" :key="'n_' + idx">
              <template slot="title">
                <i class="menu_icon iconfont" :class="item.icon"></i>
                <span>{{ item.menuName }}</span>
              </template>
              <el-menu-item-group>
                <template v-for="(child, cIdx) in item.childMenu">
                  <template v-if="child.status && child.childMenu">
                    <!--还有三级子导航  子导航前面不显示icon,所以隐藏掉-->
                    <el-submenu :index="'n_' + idx + '_' + cIdx" :key="'n_' + idx + '_' + cIdx">
                      <template slot="title">
                        <i class="menu_gap"></i>
                        <span>{{ child.menuName }}</span>
                      </template>
                      <el-menu-item-group>
                        <template v-for="(grandson, gIdx) in child.childMenu">
                          <el-menu-item class="slider-child-item" :index="grandson.path" :key="gIdx + '_g_' + grandson.path" v-if="grandson.status">
                            <!--<i class="iconfont" :class="grandson.icon"></i>-->
                            {{ grandson.menuName }}
                          </el-menu-item>
                        </template>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>
                  <template v-else>
                    <el-menu-item class="slider-child-item" :index="child.path" :key="child.path" v-if="child.status">
                      <i class="menu_gap"></i>
                      {{ child.menuName }}
                    </el-menu-item>
                  </template>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <!--一级-->
            <el-menu-item :index="item.path" :key="item.path"><i class="menu_icon iconfont" :class="item.icon"></i>{{ item.menuName }}</el-menu-item>
          </template>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menuData: [
        {
          "menuName": "工作台",
          "path": "/",
          "icon": "icon-gongzuotai",
          "status": true,//true-有权限访问；false-不能访问
        },

        {
          menuName: "课程管理",
          path: "/coursemanage",
          icon: "icon-kechengguanli",
          status: true,
        },
        {
          menuName: "商品管理",
          path: "/",
          icon: "icon-shangpin",
          status: true,
          childMenu: [
            {
              menuName: "抖店商品",
              path: "/douProduct",
              icon: "",
              status: true,
            },
            {
              menuName: "小红书商品",
              path: "/redProduct",
              icon: "",
              status: true,
            },
          ],
        },


        {
          menuName: "订单管理",
          icon: "icon-dingdan",
          path: "",
          status: true, //可能班主任没权限
          childMenu: [
            {
              menuName: "订单统计",
              path: '/statisOrder',
              icon: "",
              status: true,
            },
            {
              menuName: "抖店订单",
              path: "/douOrder",
              icon: "",
              status: true,
            },
            {
              menuName: "小红书订单",
              path: "/redOrder",
              icon: "",
              status: true,
            },
            {
              menuName: "退费申请",
              path: "/refund",
              icon: "",
              status: true,
            },
            {
              menuName: "退费审核",
              path: "/auditRefund",
              icon: "",
              status: true,
            },


          ],
        },
        {
          menuName: "教学管理",
          path: "/transferClass",
          icon: "icon-jiaoxueguanli",
          status: true,
          childMenu: [
            {
              menuName: "转班列表",
              path: "/transferClass",
              icon: "",
              status: true,
            },
            {
              menuName: "插班列表",
              path: "/joinClass",
              icon: "",
              status: true,
            },
          ],
        },
        {
          menuName: "教学数据",
          path: "/courseData",
          icon: "icon-jiaoxueshuju",
          status: true,
          childMenu: [
            {
              menuName: "行课数据",
              path: "/courseData",
              icon: "",
              status: true,
            },

          ],
        },
        {
          menuName: "学生管理",
          icon: "icon-user-manage",
          path: "/studentmanage",
          status: true,
        },
        {
          menuName: "系统管理",
          icon: "icon-xitongguanli",
          path: "/tagmanage",
          status: true,
          childMenu: [
            {
              menuName: "标签管理",
              path: "/tagmanage",
              icon: "",
              status: true,
            },
            {
              menuName: "账号管理",
              path: "/settingmanage",
              icon: "",
              status: true,
            },
            {
              menuName: "权限管理",
              path: "/permissionmanage",
              icon: "",
              status: true,
            },

          ],
        },
        {
          menuName: "系统设置",
          icon: "icon-xitongshezhi",
          path: "/settingmanage",
          status: true,
          childMenu: [
            {
              menuName: "统计设置",
              path: "/staticsSetting",
              icon: "",
              status: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.isCollapse,
    }),
    defaultActive: function () {
      let tmpPath = this.$route.path; // router里的路由地址
      // let tmpQuery = this.$route.query; // ?x=a&y=b

      if (tmpPath.indexOf("/lessonlist") != -1 ||
        tmpPath.indexOf("/lessonadd") != -1) {
        return "/classmanage";
      }
      if (tmpPath.indexOf("/courselessonlist") != -1 || tmpPath.indexOf("/midterm") != -1) {
        return "/coursemanage";
      }
      if (tmpPath.indexOf('/addemployee') != -1) {
        return "/settingmanage";
      }

      return tmpPath;

    },
  },
  mounted() {
    // menuData:state => state.userMenuData.menuData
  },
};
</script>

<style lang="scss" scoped>
.layout-navbar {
  width: 160px;
  height: 100%;

  background: #fff;
  box-shadow: 2px 0 0 #eae9e9;
  transition: all 0.3s;
  overflow-y: auto;
  overflow-x: hidden;

  &.layout-collapse {
    width: 64px;
  }

  .el-menu {
    border-right: 0;
  }

  ::v-deep .el-submenu__title:hover,
  ::v-deep .el-menu-item:hover,
  ::v-deep .el-menu-item.is-active {
    color: #f08d00 !important;
    background: #fef6eb !important;
  }
  ::v-deep .el-submenu__title:hover,
  ::v-deep .el-menu-item:hover {
    i {
      color: #f08d00;
    }
  }
  ::v-deep .el-menu-item-group__title {
    padding: 0 !important;
  }

  .menu_icon {
    width: 24px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
  }
  .menu_gap {
    text-align: center;
    display: inline-block;
    width: 5px;
  }
}
</style>