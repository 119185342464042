<template>
  <div class="layout-header">
    <div class="header-left">
      <img src="../../assets/logo.png" alt="" class="header-logo" />
    </div>
    <div class="header-flex">
      <div class="header-bread">
        <el-breadcrumb separator="/">
          <template v-for="(bread, idx) in breadObjList">
            <template v-if="bread.path == curPagePath">
              <template v-if="bread.breadArray && bread.breadArray.length">
                <template v-for="(item, sidx) in bread.breadArray">
                  <template v-if="item.path">
                    <template v-if="item.path == 'goback'">
                      <span class="el-breadcrumb__item" :key="idx + '_' + sidx" @click="goBackFun">
                        <span role="link" class="el-breadcrumb__inner is-link">{{ item.title }}</span>
                        <span role="presentation" class="el-breadcrumb__separator">/</span>
                      </span>
                    </template>
                    <template v-else>
                      <el-breadcrumb-item :to="{ path: item.path }" :key="idx + '_' + sidx">{{ item.title }}</el-breadcrumb-item>
                    </template>
                  </template>
                  <el-breadcrumb-item v-else :key="idx + '_' + sidx">{{
                    item.title
                  }}</el-breadcrumb-item>
                </template>
              </template>
              <template v-else>
                <el-breadcrumb-item :key="idx">
                  {{ bread.title }}
                </el-breadcrumb-item>
              </template>
            </template>
          </template>
        </el-breadcrumb>
      </div>
      <div class="header-user">
        <el-dropdown :hide-on-click="false" @command="handleCommand">
          <span class="edd-link">
            <img :src=" userObj.staffImg || 'https://oss.ke.live/images/default_head_img.png' " class="header-avatar" />
            <div class="header-name">{{ userObj.staffName }}</div>
          </span>

          <el-dropdown-menu slot="dropdown" class="body-header-dd-menu">
            <!--<el-dropdown-item>
                        <div class="dropdown-box">
                        <img class="dd-img" :src="userData.dingTalkHeadUrl||'//oss.ziyue.cn/common/kongziwx.png'">
                        <div class="dd-name">{{userData.userName||userData.dingTalkNickName}}</div>
                        <div class="dd-desc" v-show="userData.remark" v-html="filterUserDesc(userData.remark)"></div>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="editName">
                        <div style="text-align: center"><el-button type="primary" plain size="small" style="width: 100%;">修改资料</el-button></div>
                    </el-dropdown-item> -->
            <el-dropdown-item command="editInfo">编辑信息</el-dropdown-item>
            <el-dropdown-item command="seedetail">查看资料</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <el-button icon="el-icon-s-fold" size="small" v-show="!isCollapse" @click="toggleMenu"></el-button>
        <el-button icon="el-icon-s-unfold" size="small" v-show="isCollapse" @click="toggleMenu"></el-button> -->

    <el-dialog title="个人信息--开发中" :modal-append-to-body='false' :visible.sync="ifShowDetail" :close-on-click-modal="false" width="496px">
      <div class="dialog-info">
        <img class="di-header" :src="userObj.staffImg || 'https://oss.ke.live/images/default_head_img.png'" alt="">
        <div class="di-right">
          <div class="dir-name">{{ userObj.staffName }} -角色 </div>
          <div class="dir-status">
            <span class="tag-item tag-normal">正常</span>
            <span class="tag-item tag-zone">{{userObj.schoolZoneStr || '北京'}}</span>
          </div>
        </div>
      </div>
      <el-form ref="detailForm" size="mini">
        <el-form-item label="classIn姓名：">
          {{userObj.staffName}}
        </el-form-item>
        <el-form-item label="classIn手机号：">
          {{userObj.phone}}
        </el-form-item>
        <el-form-item label="绑定企微：">
          {{userObj.phone}}
        </el-form-item>
        <el-form-item label="学科：">
          {{userObj.subjectType||'--'}}
        </el-form-item>
        <el-form-item label="毕业院校：">
          {{userObj.college||'--'}}
        </el-form-item>
        <el-form-item label="邮箱：">
          {{userObj.email||'--'}}
        </el-form-item>
        <el-form-item label="教师资格证编号：">
          {{userObj.licenseCode||'--'}}
        </el-form-item>
        <el-form-item label="负责学段：">
          {{userObj.manageStageList||'--'}}
        </el-form-item>
        <el-form-item label="负责学科：">
          {{userObj.manageSubjectList||'--'}}
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
//breadList -- 面包屑数据，目前数据不是动态获取，这里只能先写死。每次添加一个页面，都需要添加一下。目前两级，后期可能会三级

import { mapState } from "vuex";
export default {
  data() {
    return {
      userObj: {}, //登录用户的信息
      breadObjList: [
        {
          path: "/", //跟tmpPath比较，一样则用这个
          title: "工作台",
        },
        {
          path: "/coursemanage",
          title: "课程管理",
        },
        {
          path: "/courselessonlist",
          title: "查看课节",
          breadArray: [
            {
              title: "课程管理",
              path: "/coursemanage",
            },
            {
              title: "查看课节",
            },
          ],
        },
        {
          path: "/midterm",
          title: "期中报告",
          breadArray: [
            {
              title: "课程管理",
              path: "/coursemanage",
            },
            {
              title: "期中报告",
            },
          ],
        },
        {
          path: "/transferClass",
          title: "转班列表",
          breadArray: [
            {
              title: "教学管理",
            },
            {
              title: "转班列表",
            },
          ],
        },
        {
          path: "/joinClass",
          title: "插班列表",
          breadArray: [
            {
              title: "教学管理",
            },
            {
              title: "插班列表",
            },
          ],
        },

        {
          path: "/douProduct",
          title: "抖店商品",
          breadArray: [
            {
              title: "商品管理",
            },
            {
              title: "抖店商品",
            },
          ],
        },
        {
          path: "/redProduct",
          title: "小红书商品",
          breadArray: [
            {
              title: "商品管理",
            },
            {
              title: "小红书商品",
            },
          ],
        },
        // {
        //   path: "/paylink",
        //   title: "付款链接",
        // },
        // {
        //   path: "/classmanage",
        //   title: "班级管理",
        // },
        // {
        //   path: "/lessonlist",
        //   title: "查看课节",
        //   breadArray: [
        //     {
        //       title: "班级管理",
        //       path: "/classmanage",
        //     },
        //     {
        //       title: "查看课节",
        //     },
        //   ],
        // },
        // {
        //   path: "/lessonadd",
        //   title: "添加课节",
        //   breadArray: [
        //     {
        //       title: "班级管理",
        //       path: "/classmanage",
        //     },
        //     {
        //       title: "查看课节",
        //       path: "goback", // goback表示要自己写方法执行go(-1)回到上个页面；'/xxx'表示是具体页面，直接用path即可
        //     },
        //     {
        //       title: "添加课节",
        //     },
        //   ],
        // },

        // {
        //   path: "/ordermanage", //跟tmpPath比较，一样则用这个
        //   title: "订单列表",
        //   breadArray: [
        //     {
        //       title: "首页",
        //       path: "/",
        //     },
        //     {
        //       title: "订单管理",
        //     },
        //     {
        //       title: "订单列表",
        //     },
        //   ], //按顺序展示，若有path，则加链接
        // },
        // {
        //   path: "/refundorder",
        //   title: "退费申请",
        //   breadArray: [
        //     {
        //       title: "订单管理",
        //     },
        //     {
        //       title: "退费申请",
        //     },
        //   ],
        // },
        {
          path: "/statisOrder",
          title: "订单统计",
          breadArray: [
            {
              title: "订单管理",
            },
            {
              title: "订单统计",
            },
          ],
        },
        {
          path: "/douOrder",
          title: "抖店订单",
          breadArray: [
            {
              title: "订单管理",
            },
            {
              title: "抖店订单",
            },
          ],
        },
        {
          path: "/redOrder",
          title: "小红书订单",
          breadArray: [
            {
              title: "订单管理",
            },
            {
              title: "小红书订单",
            },
          ],
        },
        {
          path: "/refund",
          title: "退费申请",
          breadArray: [
            {
              title: "订单管理",
            },
            {
              title: "退费申请",
            },
          ],
        },
        {
          path: "/auditRefund",
          title: "退费审核",
          breadArray: [
            {
              title: "订单管理",
            },
            {
              title: "退费审核",
            },
          ],
        },
        {
          path: "/courseData",
          title: "行课数据",
          breadArray: [
            {
              title: "教学数据",
            },
            {
              title: "行课数据",
            },
          ],
        },
        {
          title: "学生管理",
          path: "/studentmanage",
        },
        {
          title: "标签管理",
          path: "/tagmanage",
          breadArray: [
            {
              title: "系统管理",
            },
            {
              title: "标签管理",
            },
          ],
        },
        {
          title: "账号管理",
          path: "/settingmanage",
          breadArray: [
            {
              title: "系统管理",
            },
            {
              title: "账号管理",
            },
          ],
        },
        {
          title: "添加员工",
          path: "/addemployee",
          breadArray: [
            {
              title: "系统管理",
            },
            {
              title: "账号管理",
              path: "/settingmanage",
            },
            {
              title: "添加员工",
            },
          ],
        },
        {
          title: "权限管理",
          path: "/permissionmanage",
          breadArray: [
            {
              title: "系统管理",
            },
            {
              title: "权限管理",
            },
          ],
        },
        {
          title: "统计设置",
          path: "/staticsSetting",
          breadArray: [
            {
              title: "系统设置",
            },
            {
              title: "统计设置",
            },
          ],
        },



      ],

      ifShowDetail: false, //查看资料的弹窗
    };
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.isCollapse, //true-折叠；false-展开显示全部
    }),
    curPagePath: function () {
      //当前页面的path，用于面包屑判断
      let tmpPath = this.$route.path; // router里的路由地址

      console.log(tmpPath, "-----tmpPath  headerView.vue");
      return tmpPath;
    },
  },
  mounted() {
    this.getLoginUserInfo();
  },
  methods: {
    toggleMenu() {
      this.$store.commit("TOGGLE_MENU");
    },

    goBackFun() {
      this.$router.go(-1);
    },

    //获取登录用户的信息
    getLoginUserInfo() {
      let that = this;
      that.request({
        method: "post",
        url: "/manage/getLoginUser",
        param: {},
        success: function (res) {
          if (res.code == 200) {
            that.userObj = Object.assign({}, res.result || {});
          } else {
            that.$message.error("获取已登录用户信息失败--" + res.msg);
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error("获取信息异常");
        },
      });
    },

    //退出登录  清缓存，跳转到登录页
    handleCommand(command) {
      console.log(command);
      if (command == "logout") {
        console.log("退出登录");
        this.$confirm("确定退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.removeItem("hkmanage_session");
            localStorage.removeItem("hkmanage_username");
            localStorage.removeItem("hkmanage_path_to");
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });

        // ocalStorage.setItem('hkmanage_session',res.result) //这个必须设置，否则router.js里一直跳转到登录
        //                 }
        //                 localStorage.setItem('hkmanage_username',that.ruleForm.userName)

        //                 let willToPath = localStorage.getItem('hkmanage_path_to'
      } else if (command == "editInfo") { //编辑个人信息
        this.$router.push('/addemployee?id=' + this.userObj.id)
      } else if (command == 'seedetail') { //查看资料
        this.ifShowDetail = true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-header {
  height: 64px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  align-items: center;
  justify-content: center;

  .header-left {
    width: 160px; //宽度要与侧边栏一致
    text-align: center;
    padding: 15px 0 15px 0;
    .header-logo {
      height: 34px;
      width: 120px;
    }
  }

  .header-flex {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 24px;
    .header-bread {
      font-size: 14px;
      padding-left: 16px;
    }
    .header-user {
      flex: 1;
      text-align: right;
      line-height: 34px;

      .header-avatar {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #ccc;
        display: inline-block;
        vertical-align: middle;
      }
      .header-name {
        padding-left: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #606060;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .edd-link {
    cursor: pointer;
  }

  //弹窗的个人信息
  ::v-deep .el-form-item--mini.el-form-item {
    margin-bottom: 8px !important;
  }
  .dialog-info {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    .di-header {
      width: 51px;
      height: 51px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #eaeaea;
    }
    .di-right {
      flex: 1;
      padding-left: 12px;
      .dir-name {
        font-weight: 600;
        font-size: 16px;
        color: #606060;
        line-height: 24px;
      }
      .dir-status {
        padding-top: 2px;
        .tag-item {
          border-radius: 11px;
          border: 1px solid #e4e4e4;
          padding: 1px 12px;
          font-weight: 400;
          font-size: 13px;
          color: #999;
          line-height: 20px;
          margin-right: 8px;
        }
        .tag-normal {
          color: #52c41a;
          background: rgba(82, 196, 26, 0.1);
          border-color: #52c41a;
        }
        .tag-zone {
          color: #32c5ff;
          background: rgba(50, 197, 255, 0.1);
          border-color: #32c5ff;
        }
      }
    }
  }
}
</style>